// Custom icon static resources

import {
  BulbOutline,
  ExceptionOutline,
  InfoOutline,
  LinkOutline,
  ProfileOutline,
  FormOutline,
  DatabaseOutline,
  PartitionOutline,
  HomeOutline,
  BarChartOutline,
  MenuFoldOutline,
  MenuUnfoldOutline,
  PlayCircleOutline,
  FileTextOutline,
  TeamOutline,
  WarningOutline,
  DownloadOutline,
  FileTwoTone,
  HourglassOutline,
  MailOutline,
  FilterOutline,
  MoreOutline,
  CloudUploadOutline,
  ImportOutline,
  ExportOutline,
  DeleteOutline,
  ClockCircleOutline,
  NumberOutline,
  RightCircleOutline,
  UserAddOutline,
  MergeCellsOutline,
  CaretLeftOutline,
  CaretRightOutline,
  StepBackwardOutline,
  CaretUpOutline,
  CaretDownOutline,
  ScissorOutline,
  ApartmentOutline,
  HourglassFill,
  ExpandAltOutline,
  DeleteTwoTone,
  QuestionCircleTwoTone
} from '@ant-design/icons-angular/icons';

export const ICONS = [
  InfoOutline,
  BulbOutline,
  ProfileOutline,
  ExceptionOutline,
  LinkOutline,
  FormOutline,
  DatabaseOutline,
  PartitionOutline,
  HomeOutline,
  BarChartOutline,
  MenuFoldOutline,
  MenuUnfoldOutline,
  PlayCircleOutline,
  FileTextOutline,
  TeamOutline,
  WarningOutline,
  DownloadOutline,
  FileTwoTone,
  HourglassOutline,
  MailOutline,
  FilterOutline,
  MoreOutline,
  CloudUploadOutline,
  ImportOutline,
  ExportOutline,
  DeleteOutline,
  ClockCircleOutline,
  NumberOutline,
  RightCircleOutline,
  UserAddOutline,
  MergeCellsOutline,
  CaretLeftOutline,
  CaretRightOutline,
  StepBackwardOutline,
  CaretUpOutline,
  CaretDownOutline,
  ScissorOutline,
  ApartmentOutline,
  HourglassFill,
  ExpandAltOutline,
  DeleteTwoTone,
  QuestionCircleTwoTone
];
