import { Injectable } from '@angular/core';
import { groupBy } from 'lodash';
import { map } from 'rxjs';
import { WorkflowGQL, WorkflowNode as GovernorWorkflowNode, WorkflowNodeOperation } from 'src/app/graphql/frontend-data-graphql';
import { WorkflowMemberNode, WorkflowNode } from 'src/app/shared/components/workflow/utils/models';

@Injectable({ providedIn: 'root' })
export class WorkflowService {
  constructor(public workflowGQL: WorkflowGQL) {}

  public load() {
    return this.workflowGQL.fetch({}).pipe(
      map(response => response.data.workflow),
      map(workflowNodes => this.importFromGovernor(workflowNodes))
    );
  }

  private importFromGovernor(tree: GovernorWorkflowNode[]): { data: WorkflowNode[]; errors: string[] } {
    console.log(tree);

    const workflowNodes = [];
    const workflowNodeIds = new Set<string>();
    const errors = new Set<string>();
    for (const node of tree) {
      const workflowNode: WorkflowNode = {
        id: `w-${node.id}`,
        name: node.name,
        operation: node.operation,
        commonParentId: this.findCommonParentId(node.documentClassIds),
        documentClassIds: node.documentClassIds,
        members: [],
        links: []
      };
      if (workflowNodeIds.has(workflowNode.id)) {
        errors.add(`Duplicate workflow node id: ${node.id}`);
        continue;
      }
      if (node.operation === WorkflowNodeOperation.Extraction && node.childWorkflowIds.length > 0) {
        errors.add(`Children should not be set for Extraction node id: ${node.id}`);
        // continue;
      }
      workflowNodeIds.add(workflowNode.id);
      workflowNodes.push(workflowNode);

      for (const childWorkflowId of node.childWorkflowIds) {
        workflowNode.links.push({
          from: [],
          to: `w-${childWorkflowId}`
        });
      }

      const memberNodes = new Map<string, WorkflowMemberNode>();
      const memberIdsByGivenMemberGroupId = new Map<string, Set<string>>();
      for (const memberLink of node.memberGroups) {
        const fromIds = [];
        for (const member of memberLink.members) {
          const memberId = `m-${node.id}-${member.documentClassIds.at(0)}`;
          if (!memberIdsByGivenMemberGroupId.has(memberLink.id)) {
            memberIdsByGivenMemberGroupId.set(memberLink.id, new Set([memberId]));
          } else {
            memberIdsByGivenMemberGroupId.get(memberLink.id)?.add(memberId);
          }

          const memberNode: WorkflowMemberNode = {
            id: memberId,
            name: `${member.name}`,
            commonParentId: this.findCommonParentId(member.documentClassIds),
            documentClassIds: member.documentClassIds,
            isPhantom: memberLink.operation === WorkflowNodeOperation.Phantom,
            parentMemberIds: memberLink.parentMemberId?.length
              ? Array.from(memberIdsByGivenMemberGroupId.get(memberLink.parentMemberId)?.values() ?? [])
              : []
          };
          memberNodes.set(memberNode.id, memberNode);
          fromIds.push(memberNode.id);
        }

        for (const childWorkflowId of memberLink.childWorkflowIds) {
          workflowNode.links.push({
            from: fromIds,
            to: `w-${childWorkflowId}`
          });
        }
      }

      workflowNode.members = Array.from(memberNodes.values());
    }

    console.log(workflowNodes);

    return { data: workflowNodes, errors: Array.from(errors) };
  }

  private findCommonParentId(documentClassIds: string[]) {
    if (!documentClassIds.length) return null;

    // Split each path into components
    const splitPaths = documentClassIds.map(path => path.split('/'));

    // Assume the first path is the common prefix initially
    let commonPrefix = splitPaths[0];

    for (let i = 1; i < splitPaths.length; i++) {
      let currentPath = splitPaths[i];
      for (let j = 0; j < commonPrefix.length; j++) {
        // If the components don't match or we've reached the end of one path, truncate the commonPrefix
        if (commonPrefix[j] !== currentPath[j]) {
          commonPrefix = commonPrefix.slice(0, j);
          break;
        }
      }
    }

    // Join the common prefix components back into a path string
    return commonPrefix.join('/') || null;
  }
}
