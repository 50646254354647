import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { HotkeysService } from 'src/app/shared/services/hotkeys.service';

@Component({
  selector: 'hotkeys-action',
  template: `
    <i nz-icon nzType="lector:keyboard" class="mr-sm"></i>
    {{ 'DOCUMENT.hotkeyHelp' | translate }}
  `,
  host: {
    '[class.flex-1]': 'true'
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NzIconModule, NzButtonModule, TranslateModule]
})
export class HotkeysActionComponent {
  constructor(private hotkeys: HotkeysService) {}

  @HostListener('click')
  _click(): void {
    this.hotkeys.showHotkeyHelpModal();
  }
}
