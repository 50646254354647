import { APP_INITIALIZER, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { InMemoryCache } from '@apollo/client/cache';
import { environment } from '@env/environment';
import { ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

import { ApolloOptionsService } from './apollo-options.service';
import { relayStylePagination } from './pagination-caching';
import { AuthService } from '../shared/services/auth.service';

export function createApollo(authSrv: AuthService, apolloOptionsService: ApolloOptionsService, router: Router) {
  return () => {
    console.log('Creating Apollo Client for frontend-data ...');
    const graphql_frontend_url = `${environment.api.baseUrl}api/frontend-data/v1/graphql`;
    const backend_url = `${window.location.protocol}//${window.location.hostname}:${window.location.port}${graphql_frontend_url}`;
    const websocket_url = window.location.protocol === 'https:' ? backend_url.replace('https', 'wss') : backend_url.replace('http', 'ws');
    return apolloOptionsService
      .create(
        'frontend',
        backend_url,
        websocket_url,
        {
          watchQuery: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore'
          },
          query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all'
          }
        },
        new InMemoryCache({
          addTypename: true, // default
          resultCaching: true, // default
          // resultCacheMaxSize: Math.pow(2, 12), // The limit of the number of result objects that will be retained in memory to speed up repeated reads to the cache. The default value is `Math.pow(2, 16)`.
          typePolicies: {
            Query: {
              fields: {
                documentClasses: relayStylePagination(['filter'])
              }
            }
          }
        })
      )

      .then(() => {
        console.log('Creating Apollo Client for history ...');
        const graphql_history_url = `${environment.api.baseUrl}api/history/v1/graphql`;
        const backend_url = `${window.location.protocol}//${window.location.hostname}:${window.location.port}${graphql_history_url}`;

        return apolloOptionsService.create(
          'history',
          backend_url,
          null,
          {
            watchQuery: {
              fetchPolicy: 'no-cache',
              errorPolicy: 'ignore'
            },
            query: {
              fetchPolicy: 'no-cache',
              errorPolicy: 'all'
            }
          },
          new InMemoryCache({}),
          true
        );
      })
      .then(() => authSrv.initUserAndOrganisation())
      .then(() => {
        console.log('✅ Apollo Client initialized successfully.');
        apolloOptionsService.isOK = true;
      })
      .catch(error => {
        console.error(`❌ Apollo Client failed to initialize: ${error.message}`);
        apolloOptionsService.isOK = false;
      });
  };
}

// Async apollo initialization according to: https://stackoverflow.com/questions/66095489/angular-usefactory-return-async-function-in-module
// Make apollo link with with scalars
@NgModule({
  imports: [ApolloModule],
  providers: [
    ApolloOptionsService,
    {
      provide: APP_INITIALIZER,
      useFactory: createApollo,
      deps: [AuthService, ApolloOptionsService, Router, HttpLink],
      multi: true
    }
  ]
})
export class GraphQLModule {}
