import { Component, OnInit } from '@angular/core';
import { TitleService } from '@delon/theme';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzWaveModule } from 'ng-zorro-antd/core/wave';
import { NzIconModule } from 'ng-zorro-antd/icon';

@Component({
  templateUrl: './maintenance.component.html',
  styles: [],
  standalone: true,
  imports: [NzButtonModule, NzWaveModule, NzIconModule]
})
export class MaintenanceComponent implements OnInit {
  constructor(private titleService: TitleService) {}
  audioObj: HTMLAudioElement;
  ngOnInit(): void {
    this.audioObj = new Audio('assets/maintenance/Jeopardy-theme-song/Jeopardy-theme-song.mp3');
    this.audioObj.loop = true;
    this.audioObj.volume = 0.2;
    this.titleService.setTitle('Maintenance');
    // this.audioObj.play();
  }

  playing = false;
  toggleSound() {
    if (this.playing) {
      this.audioObj.pause();
      this.playing = false;
    } else {
      this.audioObj.play();
      this.playing = true;
    }
  }
}
