import { GraphQLInterfaceType, GraphQLObjectType, GraphQLUnionType } from 'graphql';
import { applySchemaTransforms, defaultMergedResolver } from '@graphql-tools/delegate';
import { MapperKind, mapSchema, memoize1 } from '@graphql-tools/utils';
import { generateProxyingResolvers } from './generateProxyingResolvers.js';
export const wrapSchema = memoize1(function wrapSchema(subschemaConfig) {
  const targetSchema = subschemaConfig.schema;
  const proxyingResolvers = generateProxyingResolvers(subschemaConfig);
  const schema = createWrappingSchema(targetSchema, proxyingResolvers);
  const transformed = applySchemaTransforms(schema, subschemaConfig);
  return transformed;
});
function createWrappingSchema(schema, proxyingResolvers) {
  return mapSchema(schema, {
    [MapperKind.ROOT_FIELD]: (fieldConfig, fieldName, typeName) => {
      return {
        ...fieldConfig,
        ...proxyingResolvers[typeName]?.[fieldName]
      };
    },
    [MapperKind.OBJECT_FIELD]: fieldConfig => {
      return {
        ...fieldConfig,
        resolve: defaultMergedResolver,
        subscribe: undefined
      };
    },
    [MapperKind.OBJECT_TYPE]: type => {
      const config = type.toConfig();
      return new GraphQLObjectType({
        ...config,
        isTypeOf: undefined
      });
    },
    [MapperKind.INTERFACE_TYPE]: type => {
      const config = type.toConfig();
      return new GraphQLInterfaceType({
        ...config,
        resolveType: undefined
      });
    },
    [MapperKind.UNION_TYPE]: type => {
      const config = type.toConfig();
      return new GraphQLUnionType({
        ...config,
        resolveType: undefined
      });
    },
    [MapperKind.ENUM_VALUE]: valueConfig => {
      return {
        ...valueConfig,
        value: undefined
      };
    }
  });
}