import { isEqual } from 'lodash';
import { DocumentClass, DocumentExpectations } from 'src/app/graphql/frontend-data-graphql';

export function makeBatchIntersection<T extends Record<string, any>>(array: T[]): Partial<T> {
  const result: Record<string, any[]> = {};

  // Iterate over each object in the array
  array.forEach(obj => {
    // Iterate over each key in the object
    Object.keys(obj).forEach(key => {
      // If the key doesn't exist in the result, initialize it with an empty array
      if (!result[key]) {
        result[key] = [];
      }

      // Check if the value already exists in the array using lodash's isEqual to handle deep comparison
      const valueExists = result[key].some(existingValue => isEqual(existingValue, obj[key]));

      // If the value is not already in the result array, add it
      if (!valueExists) {
        result[key].push(obj[key]);
      }
    });
  });

  return Object.entries(result)
    .filter(([k, v]) => v.length === 1)
    .reduce((acc, [k, [v]]) => ({ ...acc, [k]: v }), {});
}

function findMaxDate(dates: Array<Date | null>): Date | null {
  // Check if any date is null
  if (dates.some(date => date === null)) {
    return null;
  }

  // Find the maximum date
  return new Date(Math.max(...dates.map(date => date!.getTime())));
}

export function intersectDocumentClasses(array: DocumentClass[]): Partial<DocumentClass> {
  const expectations = array.map(e => e.expectations);
  const expectationsIntersection = makeBatchIntersection<DocumentExpectations>(expectations);
  const documentClassIntersection = makeBatchIntersection<DocumentClass>(array);
  return {
    ...documentClassIntersection,
    expectations: expectationsIntersection as DocumentExpectations,
    deletedAt: findMaxDate(array.map(e => e.deletedAt ?? null))
  };
}
