// TODO: Remove this after Node 22
export function createDeferred() {
  if (Promise.withResolvers) {
    return Promise.withResolvers();
  }
  let resolve;
  let reject;
  const promise = new Promise((_resolve, _reject) => {
    resolve = _resolve;
    reject = _reject;
  });
  return {
    promise,
    resolve: resolve,
    reject: reject
  };
}
export const leftOverByDelegationPlan = new WeakMap();
export const PLAN_LEFT_OVER = Symbol('PLAN_LEFT_OVER');
export function getPlanLeftOverFromParent(parent) {
  if (parent != null && typeof parent === 'object') {
    return parent[PLAN_LEFT_OVER];
  }
}