/**
 * By default, make no transforms for null types. If you prefer, you could use these transforms to e.g.
 * transform null into a Maybe monad.
 */
const defaultNullFunctions = {
  serialize(input) {
    return input;
  },
  parseValue(raw) {
    return raw;
  }
};
export default defaultNullFunctions;
