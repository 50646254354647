import { fragmentReducer } from "./fragment-reducer";
import { isFieldNode } from "./node-types";
import { Parser } from "./parser";
function rootTypeFor(operationDefinitionNode, schema) {
  if (operationDefinitionNode.operation === "query") {
    return schema.getQueryType() || null;
  }
  if (operationDefinitionNode.operation === "mutation") {
    return schema.getMutationType() || null;
  }
  if (operationDefinitionNode.operation === "subscription") {
    return schema.getSubscriptionType() || null;
  }
  return null;
}
export function treatResult({
  schema,
  functionsMap,
  operation,
  result,
  validateEnums,
  nullFunctions
}) {
  const data = result.data;
  if (!data) return result;
  const operationDefinitionNode = fragmentReducer(operation.query);
  if (!operationDefinitionNode) return result;
  const rootType = rootTypeFor(operationDefinitionNode, schema);
  if (!rootType) return result;
  const parser = new Parser(schema, functionsMap, validateEnums, nullFunctions);
  const rootSelections = operationDefinitionNode.selectionSet.selections.filter(isFieldNode);
  const newData = parser.parseObjectWithSelections(data, rootType, rootSelections);
  return Object.assign(Object.assign({}, result), {
    data: newData
  });
}
