import { PathLocationStrategy, APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { Optional, Inject, Injectable } from '@angular/core';
import { UrlSerializer } from '@angular/router';

@Injectable()
export class PathPreserveQueryLocationStrategy extends PathLocationStrategy {
  private get search(): string {
    return this.platformLocation?.search ?? '';
  }

  constructor(
    private platformLocation: PlatformLocation,
    private urlSerializer: UrlSerializer,
    @Optional() @Inject(APP_BASE_HREF) _baseHref?: string
  ) {
    super(platformLocation, _baseHref);
  }

  override prepareExternalUrl(internal: string): string {
    const path = super.prepareExternalUrl(internal);
    // Skip query param preservation on passport routes
    if (path == '/passport/login') return path;
    const existingURLSearchParams = new URLSearchParams(this.search);
    const existingQueryParams = Object.fromEntries(existingURLSearchParams.entries());
    // NOTE: This removes the 'id' from existing query params when navigating away from e.g. the inbox view
    delete existingQueryParams['id'];
    const urlTree = this.urlSerializer.parse(path);
    const nextQueryParams = urlTree.queryParams;
    urlTree.queryParams = { ...existingQueryParams, ...nextQueryParams };
    return urlTree.toString();
  }
}
