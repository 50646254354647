import { Kind } from "graphql";
export function isOperationDefinitionNode(node) {
  return node.kind === Kind.OPERATION_DEFINITION;
}
export function isFragmentDefinitionNode(node) {
  return node.kind === Kind.FRAGMENT_DEFINITION;
}
export function isFieldNode(node) {
  return node.kind === Kind.FIELD;
}
export function isFragmentSpreadNode(node) {
  return node.kind === Kind.FRAGMENT_SPREAD;
}
export function isInlineFragmentNode(node) {
  return node.kind === Kind.INLINE_FRAGMENT;
}
export function isNamedTypeNode(node) {
  return node.kind === Kind.NAMED_TYPE;
}
export function isListTypeNode(node) {
  return node.kind === Kind.LIST_TYPE;
}
export function isNonNullTypeNode(node) {
  return node.kind === Kind.NON_NULL_TYPE;
}
