function isPromiseLike(object) {
  return object != null && typeof object.then === 'function';
}
const defaultOnRejectedFn = reason => {
  throw reason;
};
export class ValueOrPromise {
  state;
  constructor(executor) {
    let value;
    try {
      value = executor();
    } catch (reason) {
      this.state = {
        status: 'rejected',
        value: reason
      };
      return;
    }
    if (isPromiseLike(value)) {
      this.state = {
        status: 'pending',
        value
      };
      return;
    }
    this.state = {
      status: 'fulfilled',
      value
    };
  }
  then(onFulfilled, onRejected) {
    const state = this.state;
    if (state.status === 'pending') {
      return new ValueOrPromise(() => state.value.then(onFulfilled, onRejected));
    }
    const onRejectedFn = typeof onRejected === 'function' ? onRejected : defaultOnRejectedFn;
    if (state.status === 'rejected') {
      return new ValueOrPromise(() => onRejectedFn(state.value));
    }
    try {
      const onFulfilledFn = typeof onFulfilled === 'function' ? onFulfilled : undefined;
      return onFulfilledFn === undefined ? new ValueOrPromise(() => state.value) : new ValueOrPromise(() => onFulfilledFn(state.value));
    } catch (e) {
      return new ValueOrPromise(() => onRejectedFn(e));
    }
  }
  catch(onRejected) {
    return this.then(undefined, onRejected);
  }
  resolve() {
    const state = this.state;
    if (state.status === 'pending') {
      return Promise.resolve(state.value);
    }
    if (state.status === 'rejected') {
      throw state.value;
    }
    return state.value;
  }
  static all(valueOrPromises) {
    let rejected = false;
    let reason;
    let containsPromise = false;
    const values = [];
    for (const valueOrPromise of valueOrPromises) {
      const state = valueOrPromise.state;
      if (state.status === 'rejected') {
        if (rejected) {
          continue;
        }
        rejected = true;
        reason = state.value;
        continue;
      }
      if (state.status === 'pending') {
        containsPromise = true;
      }
      values.push(state.value);
    }
    if (containsPromise) {
      if (rejected) {
        Promise.all(values).catch(() => {
          // Ignore errors
        });
        return new ValueOrPromise(() => {
          throw reason;
        });
      }
      return new ValueOrPromise(() => Promise.all(values));
    }
    return new ValueOrPromise(() => values);
  }
}
