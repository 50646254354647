import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { GraphQLError } from 'graphql';
import { NotificationConfirm, NotificationConfirmFilter, UpdateNotificationConfirmDto } from 'src/app/graphql/frontend-data-graphql';

export const NotificationConfirmUiActions = createActionGroup({
  source: 'Notification Confirm UI',
  events: {
    Select: props<{ id: string | null }>(),
    FindAllTriggered: props<{ filter: NotificationConfirmFilter }>(),
    UpdateOneTriggered: props<{ id: string; update: UpdateNotificationConfirmDto }>(),
    markAsReadTriggered: emptyProps()
  }
});

export const MyDataSourceActions = createActionGroup({
  source: 'MyDataSource',
  events: {
    FetchedPage: props<{ items: any[] }>()
  }
});

export const NotificationConfirmApiActions = createActionGroup({
  source: 'Notification Confirm API',
  events: {
    FindAllSucceeded: props<{ items: NotificationConfirm[] }>(),
    CountUnreadSucceeded: props<{ count: number }>(),
    UpdateOneSucceeded: props<{ id: string }>(),
    MarkAsReadSucceeded: emptyProps(),
    UpdatedOne: props<{ item: NotificationConfirm }>(),
    RequestFailed: props<{ errors: readonly GraphQLError[] }>()
  }
});
