import { GraphQLBoolean, GraphQLFloat, GraphQLID, GraphQLInputObjectType, GraphQLInt, GraphQLInterfaceType, GraphQLList, GraphQLNonNull, GraphQLObjectType, GraphQLString, Kind } from 'graphql';
export function createNamedStub(name, type) {
  let constructor;
  if (type === 'object') {
    constructor = GraphQLObjectType;
  } else if (type === 'interface') {
    constructor = GraphQLInterfaceType;
  } else {
    constructor = GraphQLInputObjectType;
  }
  return new constructor({
    name,
    fields: {
      _fake: {
        type: GraphQLString
      }
    }
  });
}
export function createStub(node, type) {
  switch (node.kind) {
    case Kind.LIST_TYPE:
      return new GraphQLList(createStub(node.type, type));
    case Kind.NON_NULL_TYPE:
      return new GraphQLNonNull(createStub(node.type, type));
    default:
      if (type === 'output') {
        return createNamedStub(node.name.value, 'object');
      }
      return createNamedStub(node.name.value, 'input');
  }
}
export function isNamedStub(type) {
  if ('getFields' in type) {
    const fields = type.getFields();
    // eslint-disable-next-line no-unreachable-loop
    for (const fieldName in fields) {
      const field = fields[fieldName];
      return field.name === '_fake';
    }
  }
  return false;
}
export function getBuiltInForStub(type) {
  switch (type.name) {
    case GraphQLInt.name:
      return GraphQLInt;
    case GraphQLFloat.name:
      return GraphQLFloat;
    case GraphQLString.name:
      return GraphQLString;
    case GraphQLBoolean.name:
      return GraphQLBoolean;
    case GraphQLID.name:
      return GraphQLID;
    default:
      return type;
  }
}