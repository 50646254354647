<!doctype html>
<title>Lector.ai Maintenance</title>
<style>
  body {
    text-align: center;
    padding: 150px;
  }
  h1 {
    font-size: 50px;
  }
  body {
    font:
      20px Helvetica,
      sans-serif;
    color: #333;
  }
  article {
    display: block;
    text-align: left;
    width: 650px;
    margin: 0 auto;
  }
  a {
    color: #1890ff;
    text-decoration: none;
  }
  a:hover {
    color: #333;
    text-decoration: none;
  }
</style>

<article>
  <h1>Wir sind gleich wieder da</h1>
  <div style="width: 100%; margin-bottom: 60px; margin-top: 60px">
    <img style="display: block; margin: auto" src="assets/maintenance/undraw_clean_up_ucm0.svg" height="300" />
  </div>

  <div>
    <p>
      Wir arbeiten gerade an lector.ai und sind gleich wieder für euch da. Bei Fragen könnt ihr uns jederzeit unter
      <a href="mailto:ticket@lector.ai">{{ 'ticket@lector.ai' }}</a>
      erreichen. Ansonsten sind wir so schnell wie möglich wieder verfügbar!
    </p>
    <p>&mdash; Euer lector.ai Team</p>
    <p>
      <a href="/"><button nz-button nzType="primary">Neuladen</button></a>
      <button style="margin-left: 8px" nz-button (click)="toggleSound()">
        <i nz-icon nzType="sound" nzTheme="outline"></i>
        {{ playing ? 'Untermalung aus' : 'Untermalung an' }}
      </button>
    </p>
  </div>
</article>
