import { getNullableType, isEnumType, isListType, isNonNullType, isScalarType } from "graphql";
import { isNone } from "./is-none";
import { mapIfArray } from "./map-if-array";
export class Serializer {
  constructor(schema, functionsMap, removeTypenameFromInputs, nullFunctions) {
    this.schema = schema;
    this.functionsMap = functionsMap;
    this.removeTypenameFromInputs = removeTypenameFromInputs;
    this.nullFunctions = nullFunctions;
  }
  serialize(value, type) {
    if (isNonNullType(type)) {
      return this.serializeInternal(value, getNullableType(type));
    } else {
      return this.serializeNullable(value, getNullableType(type));
    }
  }
  serializeNullable(value, type) {
    return this.nullFunctions.serialize(this.serializeInternal(value, type));
  }
  serializeInternal(value, type) {
    if (isNone(value)) {
      return value;
    }
    if (isScalarType(type) || isEnumType(type)) {
      return this.serializeLeaf(value, type);
    }
    if (isListType(type)) {
      return mapIfArray(value, v => this.serialize(v, type.ofType));
    }
    return this.serializeInputObject(value, type);
  }
  serializeLeaf(value, type) {
    const fns = this.functionsMap[type.name] || type;
    return fns.serialize(value);
  }
  serializeInputObject(givenValue, type) {
    let value = givenValue;
    if (this.removeTypenameFromInputs) {
      value = Object.assign({}, givenValue);
      delete value["__typename"];
    }
    const ret = {};
    const fields = type.getFields();
    for (const [key, val] of Object.entries(value)) {
      const f = fields[key];
      ret[key] = f ? this.serialize(val, f.type) : val;
    }
    return ret;
  }
}
