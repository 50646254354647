import { ScrollingModule } from '@angular/cdk/scrolling';
import { LOCATION_INITIALIZED, LocationStrategy, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { default as ngLang } from '@angular/common/locales/de';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  EnvironmentProviders,
  Inject,
  Injectable,
  Injector,
  Provider,
  importProvidersFrom,
  inject,
  isDevMode
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  provideRouter,
  withComponentInputBinding,
  withInMemoryScrolling,
  withHashLocation,
  RouterFeatures,
  withViewTransitions
} from '@angular/router';
import { DA_SERVICE_TOKEN, ITokenService, TokenService, authSimpleInterceptor, provideAuth, withLocalStorage } from '@delon/auth';
import { AlainProvideLang, provideAlain, en_US as delonLang } from '@delon/theme';
import { AlainConfig } from '@delon/util/config';
import { environment } from '@env/environment';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { de } from 'date-fns/esm/locale';
import { Attributes, IntersectionObserverHooks, LAZYLOAD_IMAGE_HOOKS, LazyLoadImageModule } from 'ng-lazyload-image';
import { NzConfig, provideNzConfig } from 'ng-zorro-antd/core/config';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { de_DE as zorroLang } from 'ng-zorro-antd/i18n';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { MARKED_OPTIONS, MarkedOptions, provideMarkdown } from 'ngx-markdown';

import { provideRouteReuse } from './core/cache/route-reuse.strategy';
import { provideBindAuthRefresh } from './core/net';
import { defaultInterceptor } from './core/net/default.interceptor';
import { providePreloader } from './core/startup/preloader';
import { FreshdeskService } from './freshdesk.service';
import { GraphQLModule } from './graphql/graphql.module';
import { PathPreserveQueryLocationStrategy } from './preserve-query-params';
import { appRoutes } from './routes/routes';
import { AUTH_STORAGE_KEY, MosaicAuthInfo } from './shared/services/auth.service';
import { DocumentClassEffects } from './state/document-class/document-class.effects';
import { documentClassReducer } from './state/document-class/document-class.reducer';
import { NotificationConfirmEffects } from './state/notification-confirms/notification-confirm.effects';
import { notificationConfirmReducer } from './state/notification-confirms/notification-confirm.reducer';
import { NotificationEffects } from './state/notifications/notification.effects';
import { notificationReducer } from './state/notifications/notification.reducer';
import { OrganizationEffects } from './state/organization/organization.effects';
import { organizationReducer } from './state/organization/organization.reducer';
import { PredictorEffects } from './state/predictors/predictor.effects';
import { predictorReducer } from './state/predictors/predictor.reducer';
import { provideBootstrapEffects } from './state/utils';
import { WorkflowEffects } from './state/workflow/workflow.effects';
import { workflowReducer } from './state/workflow/workflow.reducer';
import { ICONS } from '../style-icons';
import { ICONS_AUTO } from '../style-icons-auto';

export function appInitializerFactory(translateService: TranslateService, injector: Injector): () => Promise<any> {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
      locationInitialized.then(() => {
        const availableLangs = ['en', 'de', 'none'];
        translateService.addLangs(availableLangs);
        translateService.setDefaultLang('none');

        let langToSet: string | null = null;
        if (localStorage.getItem('selectedLanguage')) {
          langToSet = localStorage.getItem('selectedLanguage') as string;
        }

        if (!langToSet) {
          const browserLang = translateService.getBrowserLang();
          if (browserLang && availableLangs.includes(browserLang)) langToSet = browserLang;
        }

        if (!langToSet || !availableLangs.includes(langToSet)) langToSet = 'de';

        translateService.use(langToSet).subscribe({
          next: () => {
            console.info(`Successfully initialized '${langToSet}' language.`);
            resolve(true);
          },
          error: err => {
            console.error(`Problem with '${langToSet}' language initialization.`);
          }
        });
      });
    });
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const defaultLang: AlainProvideLang = {
  abbr: 'de',
  ng: ngLang,
  zorro: zorroLang,
  date: de,
  delon: delonLang
};

registerLocaleData(defaultLang.ng, defaultLang.abbr);

const alainConfig: AlainConfig = {
  st: { modal: { size: 'lg' } },
  pageHeader: { homeI18n: 'home' },
  auth: {
    token_send_key: 'token',
    token_send_template: 'Bearer ${token}',
    token_send_place: 'header',
    login_url: '/passport/login',
    store_key: AUTH_STORAGE_KEY,
    token_exp_offset: 10, // (default) JWT token expiration time offset value (unit: second)
    refreshTime: 4000, // [ms], internal interval to check if token expiration minus `refreshOffset` is exceeded
    refreshOffset: 8000 // [ms], recommended to set according to the multiple of refreshTime
  }
};
const ngZorroConfig: NzConfig = {};

@Injectable()
export class LazyLoadImageHooks extends IntersectionObserverHooks {
  constructor(@Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService) {
    super();
  }

  override loadImage({ imagePath }: Attributes): Promise<string> {
    const model = this.tokenService.get() as MosaicAuthInfo;
    return fetch(imagePath, {
      headers: {
        Authorization: `Bearer ${model.token}`
      }
    })
      .then(res => res.blob())
      .then(blob => URL.createObjectURL(blob));
  }
}

// NOTICE: If you use `reuse-tab` component and turn on keepingScroll you can set to `disabled`
// Pls refer to https://ng-alain.com/components/reuse-tab
const routerFeatures: RouterFeatures[] = [
  withComponentInputBinding(),
  withViewTransitions(),
  withInMemoryScrolling({ scrollPositionRestoration: 'top' })
];
if (environment.useHash) routerFeatures.push(withHashLocation());

const providers: Array<Provider | EnvironmentProviders> = [
  provideHttpClient(withInterceptors([authSimpleInterceptor, defaultInterceptor])),
  provideAnimations(),
  provideRouter(appRoutes, ...routerFeatures),
  provideAlain({ config: alainConfig, defaultLang, icons: [...ICONS_AUTO, ...ICONS] }),
  provideNzConfig(ngZorroConfig),
  provideStore({
    router: routerReducer,
    documentClass: documentClassReducer,
    notification: notificationReducer,
    notificationConfirm: notificationConfirmReducer,
    organization: organizationReducer,
    predictor: predictorReducer,
    workflow: workflowReducer
  }),
  provideAuth(withLocalStorage()),
  provideRouterStore(),
  provideEffects(),
  provideStoreDevtools({ maxAge: 25, logOnly: !isDevMode() }),
  provideRouteReuse(),
  providePreloader(),
  provideMarkdown({
    markedOptions: {
      provide: MARKED_OPTIONS,
      useValue: {
        gfm: true,
        breaks: false,
        pedantic: false,
        smartLists: true,
        smartypants: false
      } as MarkedOptions
    }
  }),
  importProvidersFrom(
    GraphQLModule,
    // following Nz*Modules lead to runtime error if not imported, which doesnt get reported at compile time:
    NzMessageModule,
    NzNotificationModule,
    NzModalModule,
    NzDrawerModule,
    ScrollingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
  ),
  //   provideStprovidestartup(), // Todo: refactor startupService as APP_INITIALIZER
  { provide: FreshdeskService, useClass: FreshdeskService },
  { provide: LocationStrategy, useClass: PathPreserveQueryLocationStrategy },
  {
    provide: APP_INITIALIZER,
    useFactory: appInitializerFactory,
    deps: [TranslateService, Injector],
    multi: true
  },
  provideBootstrapEffects(
    DocumentClassEffects,
    NotificationEffects,
    NotificationConfirmEffects,
    OrganizationEffects,
    PredictorEffects,
    WorkflowEffects
  )
];

// If you use `@delon/auth` to refresh the token, additional registration `provideBindAuthRefresh` is required
if (environment.api?.refreshTokenEnabled && environment.api.refreshTokenType === 'auth-refresh') {
  providers.push(provideBindAuthRefresh());
}

export const appConfig: ApplicationConfig = {
  providers: providers
};
