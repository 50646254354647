import { ApolloLink, Observable } from "@apollo/client/core";
import { isInputType, isLeafType } from "graphql";
import { GraphQLError } from "graphql/error/GraphQLError";
import defaultNullFunctions from "./default-null-functions";
import { mapIfArray } from "./map-if-array";
import { isListTypeNode, isNonNullTypeNode, isOperationDefinitionNode } from "./node-types";
import { Serializer } from "./serializer";
import { treatResult } from "./treat-result";
export class ScalarApolloLink extends ApolloLink {
  constructor(pars) {
    var _a, _b, _c, _d;
    super();
    this.schema = pars.schema;
    this.typesMap = (_a = pars.typesMap) !== null && _a !== void 0 ? _a : {};
    this.validateEnums = (_b = pars.validateEnums) !== null && _b !== void 0 ? _b : false;
    this.removeTypenameFromInputs = (_c = pars.removeTypenameFromInputs) !== null && _c !== void 0 ? _c : false;
    this.nullFunctions = (_d = pars.nullFunctions) !== null && _d !== void 0 ? _d : defaultNullFunctions;
    const leafTypesMap = {};
    for (const [key, value] of Object.entries(this.schema.getTypeMap())) {
      if (isLeafType(value)) {
        leafTypesMap[key] = value;
      }
    }
    this.functionsMap = Object.assign(Object.assign({}, leafTypesMap), this.typesMap);
    this.serializer = new Serializer(this.schema, this.functionsMap, this.removeTypenameFromInputs, this.nullFunctions);
  }
  // ApolloLink code based on https://github.com/with-heart/apollo-link-response-resolver
  request(givenOperation, forward) {
    const operation = this.cleanVariables(givenOperation);
    return new Observable(observer => {
      let sub;
      try {
        sub = forward(operation).subscribe({
          next: result => {
            try {
              observer.next(this.parse(operation, result));
            } catch (treatError) {
              const errors = result.errors ? [...result.errors] : [];
              if (treatError instanceof GraphQLError) {
                errors.push(treatError);
              }
              observer.next({
                errors
              });
            }
          },
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer)
        });
      } catch (e) {
        observer.error(e);
      }
      return () => {
        if (sub) sub.unsubscribe();
      };
    });
  }
  parse(operation, result) {
    return treatResult({
      operation,
      result,
      functionsMap: this.functionsMap,
      schema: this.schema,
      validateEnums: this.validateEnums,
      nullFunctions: this.nullFunctions
    });
  }
  /**
   * mutate the operation object with the serialized variables
   * @param operation
   */
  cleanVariables(operation) {
    var _a;
    const o = operation.query.definitions.find(isOperationDefinitionNode);
    const varDefs = (_a = o === null || o === void 0 ? void 0 : o.variableDefinitions) !== null && _a !== void 0 ? _a : [];
    varDefs.forEach(vd => {
      const key = vd.variable.name.value;
      operation.variables[key] = this.serialize(operation.variables[key], vd.type);
    });
    return operation;
  }
  serialize(value, typeNode) {
    if (isNonNullTypeNode(typeNode)) {
      return this.serialize(value, typeNode.type);
    }
    if (isListTypeNode(typeNode)) {
      return mapIfArray(value, v => this.serialize(v, typeNode.type));
    }
    return this.serializeNamed(value, typeNode);
  }
  serializeNamed(value, typeNode) {
    const typeName = typeNode.name.value;
    const schemaType = this.schema.getType(typeName);
    return schemaType && isInputType(schemaType) ? this.serializer.serialize(value, schemaType) : value;
  }
}
export const withScalars = pars => {
  return new ScalarApolloLink(pars);
};
