import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import version from '../../../../../../package.json';

@Component({
  selector: 'app-helper',
  templateUrl: './helper.component.html',
  styles: []
})
export class HelperComponent implements OnInit {
  packageVersion = version;

  help?: string;

  helpDict: Record<string, string> = {
    '/admin/document-types': `
    ## Documenttypen Erstellen
    Hier kann du neue Dokumententypen erstellen. Klicke auf Neu um einen neuen Typen hinzuzufügen.

    1. Dokumententypen Namen festlegen: Wähle einen Namen aus indem du auf das Stift Symbol drückst. Alle folgenden Änderungen werden automatisch gespeichert.
    2. Füge einen Untertypen hinzu. Dies ist zum Beispiel eine Rechnung oder ein Antrag.
    3. Füge eine Vorschauseite für den neuen Untertypen hinzu.
    4. Füge Felder oder Areas hinzu (typischerweise Felder).
    5. Konfiguriere das Feld.

        a. **Name**: Benenne das Feld.

        b. **Technischer Name**: Lege einen technischen Namen fest. Dieser wird bei der Übermittlung an den Kunden zur Identifikation der Felder benutzt.

        c. **Typ**: Lege einen Feldtypen fest: Text, Zahl, IBAN, IK-Nummber, Geldbetrag, etc...

        d. **Mehrere möglich**: Lege fest ob mehrere dieser Felder auf der Seite eingefügt werden können (zBsp: mehrere IK Nummern)

        e. **Pflichtfeld**: Lege fest ob das Feld ausgefüllt werden muss, bzw. explizit gelöscht werden muss, damit die Seite abgeschlossen werden kann.

        f. **UI-Größe**: Lege fest wieviel Platz das Feld in der Nutzeroberfläche einnimmt.

        g. **Seite**: Lege fest auf welcher Seite das Feld typischerweise erscheint.

        h. **Ausschnitt** (optional): Hier kann ein typisches Beispiel auf dem Beispieldokument markiert werden.

        i. **Beschreibung**: Hier erstellst du eine Markdown Dokumentation für das Feld die auf dem Feld als Fragezeichen-Symbol erreichbar ist.
    `,
    '/letter-workspace': `
    # Seitenklassifizierung

    In der Seitenklassifizierungs-Ansicht werden einzelne Seiten ihren entsprechenden Dokumentenklassen zugeordnet. Sobald links ein Brief ausgewählt wird, siehst du mittig eine Vorschau des Dokuments. Daneben sind die Seiten des Dokuments in einer kleineren Ansicht gelistet. Rechts ordnest du die Seiten den Dokumententypen zu. 
    
    <center><img src="/assets/documentation/page-classification/page-classification-1.png" alt="drawing" width="300"/></center>

    ## Dokumenten Vorschau
    
    In der Vorschau siehst du die erste Seite der Briefe. Mit den Icons in der Mitte kannst du den Brief drucken, drehen oder zoomen. 
    
    ## Seiten Vorschau 
    
    Neben der Vorschau der ersten Seite siehst du alle Seiten des Briefes durchnummeriert mit "Brief 1", "Brief 2", usw. Seiten die noch nicht einer Klasse zugeordnet wurden, sind mit einem "?" gekennzeichnet. Sobald du die Seiten klassifizierst und eine Seitennummer innerhalb dieses neuen Dokuments spezifizierst, siehst du in dieser Ansicht auch diese Nummerierung, z.B. "Seite 1", und die Klasse des Dokuments, z.B. "Rechnung".
    
    ## Klassifizierung

    <center><img src="/assets/documentation/page-classification/page-classification-2.png" alt="drawing" width="300"/></center>

    Auf der rechten Seite findest du die Dokumententyp-Vorschläge zur Klassifizierung der Seiten. Wenn du Seiten klassifizierst und sie damit einem Dokumenttyp hinzufügst, kann das Dokument entweder **neu** sein oder **bereits bestehen**. Neue Dokumente haben ein "(Neu)" vor der Dokumentenbezeichnung, bereits bestehende Dokumente sind durchnummeriert (z.B. "#1"). Ein Vorschlag kann z.B. "(Neu) Rechnung" sein. Zusätzlich zum Vorschlag selber, zeigen wir die Konfidenzwerte für den Dokumenttyp an. Sobald du eine Seite klassifizierst ändert die Ansicht auf Details der aktuellen Seite. Dort siehst du neben der aktuellen Seite aber auch alle klassifizierten Seiten pro Dokumenttyp. Optional (je nach Konfiguration) kannst du in dieser Ansicht wählen ob es sich bei der aktuellen Seite um ein Scan oder ein Foto handelt. 
    
    **Seite einem neuen Dokument zuordnen**
    Die Seite die du gerade in der Seiten Vorschau ausgewählt hast, ordnest du dann der Klasse zu, indem du auf das grüne "+" klickst. Damit erstellst du ein neues Dokument, z.B. eine neue Rechnung. 
    Für einige Klassen gibt es die Möglichkeit die Seitennummer zu spezifizieren. In diesem Fall siehst du unter der vorgeschlagenen Klasse, die Kästen "1", "2", "3", "+" usw. Die Seiten spezifisierst du dann indem du zuerst auf die entsprechende Seite im Dokument, dann auf Kästchen "1" klickst. 
    
    <center><img src="/assets/documentation/page-classification/page-classification-3.png" alt="drawing" width="300"/></center>

    **Seite einem bestehenden Dokument zuordnen**
    Wurden bereits Dokumente erstellt, sind diese Dokumententyp-Vorschläge durchnummeriert, z.B. "#1 Protokoll" Auch hier kannst du Seiten hinzufügen. Entweder du spezifizierst die Seitennummer, oder fügst Seiten mit dem "+" hinzu. 
    
    **Seiten aus einem bestehenden Dokument löschen**
    In der Detailansicht der aktuellen Seite hast die Möglichkeit gesamte Klassen oder einzelne Seiten mit dem "x" zu löschen.
    `,
    '/letter-classification': `
    # Status Anzeige

    In der Status Anzeige werden alle Briefe gelistet die sich derzeit im System befinden. Hier können neue Dateien entweder per drag-and-drop oder durch auswählen in deiner lokalen Ordnerstruktur hinzugefügt werden. Der Tab zeigt den Status an un wieviele Briefe sich gerade in diesem Status befinden. Weitere Details wie Bearbeiter, erkannte Dokumententypen, usw. sind in den jeweiligen Tabs zu sehen.

    **KI-Bearbeitung:** hier befinden sich alle Briefe die derzeit von der KI bearbeitet werden. An dem Status lässt sich erkennen was gerade mit diesem Brief passiert (Seitenklassifikation oder Extraktion).

    **Klassifizierung:** hier findet man die Dokumente, welche zur Überprüfung der Klasifizierung bereitstehen. Es wird zusätzlich die dazugehörigen erkannten und erfassten Dokumententypen, die Seitenzahlen, das Erstellungsdatum und der letzter Bearbeiter angezeigt.

    **Abgeschlossen:** hier werden alle abgeschlossenen Briefe gelistet.

    **Exportiert:** alle erfolgreich exportierten Briefe.

    **Fehlerhaft:** alle Briefe bei denen es in der Bearbeitung Fehlermeldungen gab.

    **Gelöscht:** alle gelöschten Briefe.
    `,
    '/extraction': `
    # Übersicht Extraktion

    Im linken Auswahlfeld lassen sich die verschiedenen Dokumentenkategorien auswählen. Eine Kategorie kann mehrere Dokumententypen beinhalten. In dieser Ansicht befinden sich zusätzlich Zähler die angeben, wieviele Briefe gerade zur Überprüfung (Klassifizierung/Extraktion) zur Verfügung stehen.

    In der Status Anzeige werden alle Briefe gelistet die sich derzeit im System befinden. Hier können neue Dateien entweder per drag-and-drop oder durch auswählen in deiner lokalen Ordnerstruktur hinzugefügt werden. Der Tab zeigt den Status an un wieviele Briefe sich gerade in diesem Status befinden. Weitere Details wie Bearbeiter, erkannte Dokumententypen, usw. sind in den jeweiligen Tabs zu sehen.

    **KI-Bearbeitung:** hier befinden sich alle Briefe die derzeit von der KI bearbeitet werden. An dem Status lässt sich erkennen was gerade mit diesem Brief passiert (Seitenklassifikation oder Extraktion).

    **Klassifizierung:** hier findet man die Dokumente, welche zur Überprüfung der Seitenklasifizierung bereitstehen. Es wird zusätzlich die dazugehörigen erkannten und erfassten Dokumententypen, die Seitenzahlen, das Erstellungsdatum und der letzter Bearbeiter angezeigt.

    **Extraktion:** hier findet man die Dokumente, welche zur Extraktion der Felder bereit steht.

    **Abgeschlossen:** hier werden alle abgeschlossenen Briefe gelistet.

    **Exportiert:** alle erfolgreich exportierten Briefe.

    **Fehlerhaft:** alle Briefe bei denen es in der Bearbeitung Fehlermeldungen gab.

    **Gelöscht:** alle gelöschten Briefe.
    `,
    '/extraction/group': `
    # Extraktion
    
    Hier folgt demnächst eine detaillierte Beschreibung der Extraktionsoberfläche.
    `,
    '/': `# Dashboard
    
    Hier findest du einen Überblick über die verarbeiteten Briefe und Dokumente.
    `
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
    const url: string = this.router.url.split('/').slice(0, 3).join('/');
    console.log(url);
    const keys = Object.keys(this.helpDict);
    const matchingKey = keys.find(k => k.includes(url));
    if (matchingKey) {
      this.help = this.helpDict[url];
    }
  }

  ngOnInit(): void {
    console.log('helper initialized');
  }
}
