import { APP_INITIALIZER, Injectable, makeEnvironmentProviders } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { stepPreloader } from '@delon/theme';
import { filter, take } from 'rxjs/operators';

export function initializePreloader(router: Router) {
  const donePreloader = stepPreloader();
  return (): void => {
    router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        take(1)
      )
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          donePreloader();
        }
      });
  };
}

export function providePreloader() {
  return makeEnvironmentProviders([
    {
      provide: APP_INITIALIZER,
      useFactory: initializePreloader,
      deps: [Router],
      multi: true
    }
  ]);
}
