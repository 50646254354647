import { APP_BOOTSTRAP_LISTENER, InjectionToken, Inject, Type } from '@angular/core';
import { EffectSources, FunctionalEffect } from '@ngrx/effects';

// effects will be initialized BEFORE (!!!) APP_INITIALIZERs, which will collide with apollo initialization in injected services
// nasty workaround discussed here: https://github.com/ngrx/platform/issues/931#issuecomment-379318528

export const BOOTSTRAP_EFFECTS = new InjectionToken('Bootstrap Effects');

export function bootstrapEffects(effects: Array<Type<any>>, sources: EffectSources) {
  return () => {
    effects.forEach(effect => sources.addEffects(effect));
  };
}

export function createInstances(...instances: any[]) {
  return instances;
}

export function isClass(classOrRecord: Type<unknown> | Record<string, unknown>): classOrRecord is Type<unknown> {
  return typeof classOrRecord === 'function';
}

export function getClasses(classesAndRecords: Array<Type<unknown> | Record<string, unknown>>): Array<Type<unknown>> {
  return classesAndRecords.filter(isClass);
}

export function provideBootstrapEffects(...effects: Array<Type<unknown> | Record<string, FunctionalEffect>>) {
  const effectsClassesAndRecords = effects.flat();
  const effectsClasses = getClasses(effectsClassesAndRecords);
  return [
    effectsClasses,
    { provide: BOOTSTRAP_EFFECTS, deps: effectsClasses, useFactory: createInstances },
    {
      provide: APP_BOOTSTRAP_LISTENER,
      multi: true,
      useFactory: bootstrapEffects,
      deps: [[new Inject(BOOTSTRAP_EFFECTS)], EffectSources]
    }
  ];
}
