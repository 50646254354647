import { applySchemaTransforms } from './applySchemaTransforms.js';
export function isSubschema(value) {
  return Boolean(value.transformedSchema);
}
export class Subschema {
  name;
  schema;
  executor;
  batch;
  batchingOptions;
  createProxyingResolver;
  transforms;
  _transformedSchema;
  merge;
  constructor(config) {
    this.name = config.name;
    this.schema = config.schema;
    this.executor = config.executor;
    this.batch = config.batch;
    this.batchingOptions = config.batchingOptions;
    this.createProxyingResolver = config.createProxyingResolver;
    this.transforms = config.transforms ?? [];
    this.merge = config.merge;
  }
  get transformedSchema() {
    if (!this._transformedSchema) {
      if (globalThis.process?.env?.['DEBUG'] != null) {
        console.warn('Transformed schema is not set yet. Returning a dummy one.');
      }
      this._transformedSchema = applySchemaTransforms(this.schema, this);
    }
    return this._transformedSchema;
  }
  set transformedSchema(value) {
    this._transformedSchema = value;
  }
}