import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { QueryRef } from 'apollo-angular';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import { NotificationConfirm } from 'src/app/graphql/frontend-data-graphql';
import {
  MyDataSourceActions,
  NotificationConfirmApiActions,
  NotificationConfirmUiActions
} from 'src/app/state/notification-confirms/notification-confirm.actions';
import { selectAllNotificationConfirms } from 'src/app/state/notification-confirms/notification-confirm.selectors';

export class MyDataSource extends DataSource<NotificationConfirm> {
  private pageSize = 10;
  private disconnect$ = new Subject<void>();
  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();

  constructor(private store: Store) {
    super();
  }

  connect(collectionViewer: CollectionViewer): Observable<NotificationConfirm[]> {
    this.fetchPage();
    return this.store.select(selectAllNotificationConfirms).pipe(
      tap(() => this.loadingSubject.next(true)),
      switchMap(data => {
        this.loadingSubject.next(false);
        return [data];
      }),
      takeUntil(this.disconnect$)
    );
  }

  disconnect(): void {
    this.disconnect$.next();
    this.disconnect$.complete();
  }

  private fetchPage(): void {
    this.store.dispatch(NotificationConfirmUiActions.findAllTriggered({ filter: {} }));
  }
}
