import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { PlatformRole } from 'src/app/graphql/frontend-data-graphql';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DocumentService } from 'src/app/shared/services/document.service';

@Component({
  selector: 'header-search',
  template: `
    <nz-input-group [nzPrefix]="loadingTpl" [nzSuffix]="iconTpl">
      <ng-template #iconTpl>
        <i nz-icon [nzType]="'right-circle'"></i>
      </ng-template>
      <ng-template #loadingTpl>
        @if (loading) {
          <i nz-icon nzType="loading"></i>
        }
      </ng-template>
      <input
        style="width:200px;"
        type="text"
        nz-input
        [(ngModel)]="q"
        [nzAutocomplete]="auto"
        (input)="search($event)"
        (focus)="qFocus()"
        (blur)="qBlur()"
        (keyup.enter)="sendSearch(q)"
        [attr.placeholder]="'BASIC.SEARCH.goToDocument' | translate"
        nz-tooltip
        [nzTooltipTitle]="this.auth.acl.can([PlatformRole.Developer]) ? ('BASIC.SEARCH.searchDeveloperHelp' | translate) : ''"
        [nzTooltipMouseEnterDelay]="0.75"
        nzTooltipPlacement="bottom" />
    </nz-input-group>
    <nz-autocomplete nzBackfill #auto>
      @for (i of options; track i) {
        <nz-auto-option [nzValue]="i">{{ i }}</nz-auto-option>
      }
    </nz-autocomplete>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NzInputModule, NzButtonModule, NzIconModule, FormsModule, NzAutocompleteModule, NzToolTipModule, TranslateModule]
})
export class HeaderSearchComponent implements AfterViewInit, OnDestroy {
  q = '';
  qIpt: HTMLInputElement | null = null;
  options: string[] = [];
  search$ = new BehaviorSubject('');
  loading = false;

  @HostBinding('class.alain-default__search-focus')
  focus = false;

  @HostBinding('class.alain-default__search-toggled')
  searchToggled = false;

  PlatformRole = PlatformRole;

  @Input()
  set toggleChange(value: boolean) {
    if (typeof value === 'undefined') {
      return;
    }
    this.searchToggled = value;
    this.focus = value;
    if (value) {
      setTimeout(() => this.qIpt!.focus());
    }
  }

  @Output() readonly toggleChangeChange = new EventEmitter<boolean>();

  constructor(
    private el: ElementRef<HTMLElement>,
    private cdr: ChangeDetectorRef,
    private messageService: NzMessageService,
    public auth: AuthService,
    private documentService: DocumentService,
    private router: Router,
    private modal: NzModalService
  ) {}

  ngAfterViewInit(): void {
    this.qIpt = this.el.nativeElement.querySelector('.ant-input') as HTMLInputElement;
    this.search$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap({
          complete: () => {
            this.loading = true;
          }
        })
      )
      .subscribe(value => {
        // this.options = value ? [`DocumentId: ${value}`, `Path: ${value}`] : [];
        this.loading = false;
        this.cdr.markForCheck();
      });
  }

  qFocus(): void {
    this.focus = true;
  }

  qBlur(): void {
    this.focus = false;
    this.searchToggled = false;
    this.options.length = 0;
    this.toggleChangeChange.emit(false);
  }

  search(ev: Event): void {
    this.search$.next((ev.target as HTMLInputElement).value);
  }

  ngOnDestroy(): void {
    this.search$.complete();
    this.search$.unsubscribe();
  }

  async sendSearch(search: string) {
    const checkForHexRegExp = /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i;
    const isMongoId = checkForHexRegExp.test(search);

    if (search.match('.png') || search.match('.jpg')) {
      const base64 = await this.auth.getSecureBase64AsString(search);
      const content = `<div style="width:100%"><img src="${base64}" style="max-width: 100%"/></div>`;
      this.modal.create({
        nzContent: content
      });
      return;
    }

    if (search.match('.pdf')) {
      const base64 = await this.auth.getSecureBase64AsString(search);
      window.open(base64);
      return;
    }

    this.documentService.documents
      .fetch({
        filter: { or: [{ id: { eq: search } }, { title: { eq: search } }] }
      })
      .subscribe(res => {
        if (res.data && res.data.documents?.edges?.length > 0) {
          this.router.navigate(['documents', res.data.documents.edges[0].node.id]);
        } else {
          this.messageService.info('Kein Ergebnis gefunden.');
        }
      });
  }
}
